
import React, { useCallback, useState } from "react";
import { Col, Row, Form, Button, Container, InputGroup } from 'react-bootstrap';
import { ADD_TO_CART } from "../constant/constants";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { renderField, required, email, notification } from "../services/utileServices";
import { submitUserDetail, removeFromCart } from "../services/services";
import Loader from "react-js-loader";
import {data} from "../components/Language";


let CheckoutForm = (props) => {
  let dispatch = useDispatch();
  let cartData = useSelector(state => state.cartReducer);
  let lang = useSelector(state => state.language.lang);
  cartData.subTotal = props.totalPrice;
  let parsedata = JSON.stringify(cartData);
  const [cardItem, setCartItem] = useState(parsedata);
  const [loader, setLoader] = useState(0);
  const onSubmitForm = useCallback(async (values) => {
    let formData = { FIRST_NAME: values.fname, LAST_NAME: values.lname, EMAIL_ID: values.email, PHONE_NO: values.phone, CART_DATA: cardItem, SEAT_NO: props.seatNo };
    await submitUserDetail(formData).then(response => {
      if (response && response.data && response.data.success) {
        removeFromCart();
        dispatch({ type: ADD_TO_CART, payload: [], totalItem: 0 });
        setLoader(0);
        props.onClose();
        notification("Thank You!", "Your order has been placed", "success")
      } else {
        notification('Warning', response.data.error, 'warning');
      }
    }).catch(err => {
      notification('Api Error', 'Error in Order Submit Api call ' + err.message, 'danger');
    });

  })
  const { handleSubmit, submitting, error } = props;

  return (
    <>

      <div className="p-3">
        <div className="text-center text-md-center mb-4 mt-md-0">
          <h3 className="mb-0">{data.INSERT_YOUR_DATA[lang]}</h3>
        </div>
        <Form className={loader ? "d-none" : "mt-4"} onSubmit={handleSubmit(onSubmitForm)}>
          <Form.Group id="fname" className="mb-4">
            <Form.Label>{data.FIRST_NAME[lang]}</Form.Label>
            <InputGroup>
              <Field name="fname" type="text" label={data.FIRST_NAME[lang]} component={renderField} validate={[required]} />
            </InputGroup>
          </Form.Group>
          <Form.Group id="lname" className="mb-4">
            <Form.Label>{data.SURNAME[lang]}</Form.Label>
            <Field name="lname" type="text" label={data.SURNAME[lang]} component={renderField} validate={[required]} />
          </Form.Group>
          <Form.Group id="email" className="mb-4">
            <Form.Label>{data.EMAIL[lang]}</Form.Label>
            <InputGroup>
              <Field name="email" label={data.EMAIL[lang]} type="email" component={renderField} validate={[required, email]} />
            </InputGroup>
          </Form.Group>
          <Form.Group id="phone" className="mb-4">
            <Form.Label>{data.MOBILE_NUMBER[lang]}</Form.Label>
            <InputGroup>
              <Field name="phone" type="tel" label={data.MOBILE_NUMBER[lang]} component={renderField} validate={[required]} />
            </InputGroup>
          </Form.Group>
          <button disabled={submitting} style={{backgroundColor:'rgb(191 31 42)', color:'#fff', fontWeight:'600', padding:'8px 20px', border:'0px', borderRadius:'50px'}} type="submit" className="d-block mx-auto" onClick={() => setLoader(1)}>
            {data.PROCEED_TO_CHECKOUT[lang]}
          </button>
        </Form>
      </div>
      {loader ? <Loader type="spinner-default" bgColor={"#BE1622"} color={'#BE1622'} size={150} /> : null}
    </>
  );
};
CheckoutForm = reduxForm({
  form: 'check-out-form'
})(CheckoutForm)

export default CheckoutForm;

