import React, { useEffect, useCallback, useState } from "react";
import { FaAngleLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom'
import { ADD_TO_CART, SINGLE_ITEM, SLUG } from "../constant/constants";
import { AddItemInCart, getSingleItem } from "../services/services";
import { getItemById } from "../services/services";
import { notification } from "../services/utileServices";
import Loader from "react-js-loader";
import Footer from "./Footer";
import { data } from "./Language";

const ItemsDetails = () => {
    const single = useSelector(state => state.item.singleitem);
    let lang = useSelector(state => state.language.lang);
    let allAllergens = useSelector(state => state.allergens.allergenbyid);
    let dispatch = useDispatch();
    let history = useNavigate();
    
    let { slug, id } = useParams();
    const [loader, setLoader] = useState(1);
    const [loader1, setLoader1] = useState(0);
    const onHandleAddToCart = useCallback(async (id) => {
        setLoader1(1);
        await getItemById(id).then(result => {
            if (result.data && result.data.success) {
                let product = {};
                product = result.data.data[0];
                
                let payload = AddItemInCart(product);
                dispatch({ type: ADD_TO_CART, payload: payload.data, totalItem: payload.totalItem })
                notification(data.ITEM_ADDED[lang], data.ITEM_ADDED_IN_CART[lang], 'success');
                setLoader1(0);
            } else {
                console.log("data not added to cart");
                setLoader1(0);
            }
        }).catch(err => {
            notification('Network Error', 'Error in Get Item ' + err.message, 'danger');
            setLoader1(0);
        });

    })

    useEffect(() => {
        const GetSingleItem = async (id) => {
            await getSingleItem(id).then(result => {
                if (result.data && result.data.success) {
                    let payload = {};
                    payload = result.data;
                    dispatch({ type: SINGLE_ITEM, payload });
                    console.log('item fetch successfully');
                    setLoader(0);
                } else {
                    console.log('no item exist');
                    setLoader(0);
                }
            }).catch(err => {
                console.log('error in get single item' + err.message);
                setLoader(0);
            })
        }

        if (single == undefined) {
            GetSingleItem(id);
        } else {
            setLoader(0);
        }

        if (slug != undefined) {
            let payload = slug;
            dispatch({ type: SLUG, payload });
        }
    }, [single, id])

    return (
        <>
            {!loader ?
                (single != undefined && single.AVAILABLE == 1) ?
                    <div className="main_card">
                        <div className="head" >
                            <span onClick={() => history(`/${slug}/menu`)}><FaAngleLeft /></span>
                            <h2 style={{textTransform:'capitalize'}}>{single ? single.ITEM_NAME[lang]?single.ITEM_NAME[lang]:single.ITEM_NAME.en : null}</h2>
                        </div>
                        <div>
                            <img src={(single && single.IMG_ITEM) ? `${process.env.REACT_APP_FRONT_END_API_URL + single.IMG_ITEM}` : "/demo.png"} alt="itemsImage" className="w-100" />
                        </div>
                        <div className="dis_items bg-white" style={{ padding: '15px 20px' }}>
                            <p className="m-0 pb-2" style={{ fontSize: '16px', color: '#585858', textTransform:'capitalize' }}>
                                {single ? single.DESCRIPTION[lang]?single.DESCRIPTION[lang]:single.DESCRIPTION.en : null}
                            </p>
                            <h5 style={{ color: '#be1622', fontSize: '22px', fontWeight: '700' }}>{single ? single.PRICE : null} €</h5>
                            <hr />
                            <div className="d-flex align-items-center justify-content-between pt-2">
                                <div className="d-flex align-items-center flex-wrap ">
                                    {
                                        ((allAllergens && allAllergens.length) ? (
                                            allAllergens.map((allergen, key) => <h5 style={{ fontSize: '22px', fontWeight: '700', margin: '0 15px 0 0', textTransform:'capitalize' }} key={key}>{allergen.ALLERGEN[lang]}</h5>)
                                        ) : <p className="mb-2" style={{ fontSize: '13px', textTransform:'capitalize' }}> {data.ITEM_DETAILS_TEXT_NO_AL[lang]} </p>)
                                    }
                                </div>

                                <div style={{ height: '25px' }} className="item-details-loader">{!loader1 ? <img onClick={() => onHandleAddToCart(single.ITEM_ID)} src="/aggiungi.svg" alt="text" style={{ width: '40px', height: 'auto', cursor: 'pointer' }} /> : <Loader type="spinner-default" bgColor={"#BE1622"} color={'#BE1622'} size={50} />}</div>
                            </div>
                            {/* <p className="m-0 pt-2" style={{ fontSize: '16px', color: '#585858' }}>{data.ITEM_DETAILS_TEXT_F[lang]}</p> */}
                        </div>
                    </div> : history(`/${slug}/menu`) : <Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} />

            }
            <Footer />
        </>
    )
}

export default ItemsDetails;