import axios from 'axios';

const getCart = () => {
    const cart = localStorage.getItem('scevoo_cart');
    return cart;
}

const AddItemInCart = (product) => {
    var cart = JSON.parse(getCart());
    let newCart = [];
    let duplicates = false;
    let payload = {};
    // Checking if Cart is already Empty
    if (!cart) {
        product.total = parseInt(product.PRICE);
        product.quantity = 1;
        newCart.push(product);
        payload.totalItem = product.quantity;
        payload.data = newCart;
        createCart(JSON.stringify(newCart));
        setTotalItem(product.quantity);
    }
    // Checking If Requested Item to add in cart already exist
    else if (cart) {
        for (let item of cart) {
            if (item.ITEM_ID === product.ITEM_ID) {
                let newTotalItem = getTotalItem();
                duplicates = true;
                ++item.quantity;
                ++newTotalItem;
                item.total = parseInt(item.total) + parseInt(product.PRICE);
                newCart = [...cart];
                payload.data = newCart;
                payload.totalItem = newTotalItem;
                setTotalItem(newTotalItem);
                createCart(JSON.stringify(newCart));
            }
        }
    }
    // Adding Item if requested item is nither duplicate nor cart is empty
    if (!duplicates && cart) {
        product.quantity = 1;
        let newTotalItem = getTotalItem();
        ++newTotalItem;
        product.total = product.PRICE;
        newCart = [...cart, product];
        payload.totalItem = newTotalItem;
        payload.data = newCart;
        setTotalItem(newTotalItem);
        createCart(JSON.stringify(newCart));
    }

    return payload;

}

const getTotalItem = () => {
    return localStorage.getItem('total_item');
}

const setTotalItem = (totalItem) => {
    localStorage.setItem('total_item', totalItem);
    return true;
}

const createCart = (cart) => {
    localStorage.setItem('scevoo_cart', cart);
    return (true);
}

const removeFromCart = () => {
    localStorage.removeItem('scevoo_cart');
    localStorage.removeItem('sub_total');
    localStorage.removeItem('total_item');
    return true;
}

// function getAllItems(){
//     return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}front/item/get`);
// }


// function getAllAllergen(){
//     return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}front/allergen/get`);
// }

// function getCategoryById(id){
//     return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}category/getbyid/${id}`);
// }

function getItemById(id) {
    return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}item/getbyid/${id}`);
}

// function getAllergenById(id){
//     return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}allergen/getbyid/${id}`);
// }

function getRestaurantSlug(slug) {
    return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}restaurant/getbyslug/${slug}`);
}
function getRestaurantById(id) {
    return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}restaurant/getbyid/${id}`);
}

function getAllCategoryByRestId(slug) {
    return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}front/category/get/${slug}`);
}

function getItemByCatId(cid, restid) {
    return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}item/getbycatrest/${cid}/${restid}`);
}

//no use
function getAllergensByRestId(id) {
    return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}allergen/getbyrestid/${id}`);
}

async function getAllergensByItemId(id, rest_id) {
    return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}getallergenmap/${id}/${rest_id}`);
}

function addItemToCart(id) {
    axios.post(`${process.env.REACT_APP_FRONT_END_API_URL}cart/additem/${id}`);
}

function submitUserDetail(data) {
    return axios.post(`${process.env.REACT_APP_FRONT_END_API_URL}order/details`, data)
}

function getSingleItem(id) {
    return axios.get(`${process.env.REACT_APP_FRONT_END_API_URL}item/getbyid/${id}`);
}

const setLangCode = (lang) => {
    localStorage.setItem('lang_code', lang);
    return true;
}

const getLangCode = () =>{
    let lang = localStorage.getItem('lang_code');
    return lang;
}

function detectLang(inputText){
    return axios.post(`https://libretranslate.de/detect`, {q: inputText});
}

export {
    getSingleItem,
    getItemByCatId,
    getRestaurantSlug,
    getCart,
    createCart,
    getAllCategoryByRestId,
    getAllergensByRestId,
    addItemToCart,
    getItemById,
    getTotalItem,
    setTotalItem,
    submitUserDetail,
    removeFromCart,
    getAllergensByItemId,
    getRestaurantById,
    AddItemInCart,
    setLangCode,
    getLangCode,
    detectLang
}