export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const RESTAURANT_BY_ID = 'REATAURANT_BY_ID';
export const CATEGORY_BY_REST_ID = 'CATEGORY_BY_ID';
export const ALLERGEN_BY_REST_ID = 'ALLERGEN_BY_ID';
export const ITEM_BY_CAT_ID = 'ITEM_BY_ID';
export const CART_ITEM = 'CART_ITEM';
export const APP_LOAD = 'APP_LOAD';
export const APP_LOAD_TOKEN = 'APP_LOAD_TOKEN';
export const PROFILE_IMAGE = 'PROFILE_IMAGE';
export const CATEGORY_ITEM = 'CATEGORY_ITEM';
export const CURRENT_LOCATION = 'CURRENT_LOCATION';
export const ALLERGEN_BY_ITEM_ID = 'ALLERGEN_BY_ITEM_ID';
export const SLUG = 'SLUG';
export const RESET_CART = 'RESET_CART';
export const SINGLE_ITEM = 'SINGLE_ITEM';
export const LANGUAGE = 'LANGUAGE';
export const HEADER = 'HEADER';
