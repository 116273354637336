import React, { useCallback, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import { CircleFlag } from 'react-circle-flags'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getRestaurantById, setLangCode, detectLang } from "../services/services";
import { SLUG, LANGUAGE, RESTAURANT_BY_ID } from "../constant/constants";
import { notification } from "../services/utileServices";

const Header = () => {
        const [show, toggleShow] = useState(false);
        let lang = useSelector((state) => state.language.lang);
        const restData = useSelector(state => state.restaurant.restbyid);
        const [countryCode, setCountryCode] = useState('');
        const slug = useSelector(state => state.app.slug);
        const dispatch = useDispatch();
        const cartData = useSelector((state) => state.cartReducer);
        let [countClick, setCountClick] = useState(0);
        let restid;
        if (cartData && cartData.cart && cartData.cart[0] != undefined) {
                restid = cartData.cart[0].RESTAURANT_ID
        }

        useEffect(() => {
                const onGetRestData = async (id) => {
                        await getRestaurantById(id).then(result => {
                                if (result.data && result.data.success) {
                                        let payload = {};
                                        payload = result.data;
                                        let slug = payload.data.SLUG
                                        dispatch({ type: RESTAURANT_BY_ID, payload });
                                        dispatch({ type: SLUG, slug });
                                }
                        }).catch(err => {
                                notification('Api Error', 'Error in Get Restaurant by Slug ' + err.message, 'danger');
                        });
                }
                if (restid != undefined) {
                        onGetRestData(restid);
                }

                if (lang != undefined) {
                        setCountryCode(lang);
                }

        }, [restid, lang])


        const setNotification = (slug) => {
                if (slug == undefined) {
                        notification('No Item In Cart', 'Your Cart is Empty', 'warning');
                }
        }

        useEffect(() => {
                function handleClick() {
                        if (countClick == 2) {
                                toggleShow(false);
                                setCountClick(0);
                        } else {
                                if(countClick>1){
                                        toggleShow(false);
                                }else{
                                        setCountClick(countClick+=1); 
                                }
                        }
                }
                document.body.style.minHeight = '100vh';
                document.body.addEventListener('click', handleClick);
                return () => {
                        document.body.removeEventListener('click', handleClick);
                };
        }, [show])

        const onChangeLang = useCallback(async (lang) => {
                setCountryCode(lang);
                setLangCode(lang);
                let payload = lang;
                dispatch({ type: LANGUAGE, payload });
        })

        const handleCountryShow = () => {
                toggleShow(!show)
                setCountClick(countClick+=1); 
        }

        return (
                <>
                        <div id={"HeaderToggle"} className={"header_view main fixed-top"} style={{ padding: '0 10px', boxShadow: '0 0 2px #000' }}>
                                <Row className="" style={{ backgroundColor: '#fff', height: '80px' }}>
                                        <div className="d-flex align-items-center justify-content-between px-3">
                                                <div>
                                                        {/* <Link to={`/${slug}`} ><img src="/images/logo.png" alt="text" style={{ width: '140px', height: 'auto' }} /></Link> */}
                                                        <Link to={`/${slug}`} style={{ textDecoration: 'none' }}>
                                                                <div className="header_content py-4" style={{ minHeight: '93px' }}>
                                                                        <h2 className="header_h2 m-0" style={{ fontSize: '20px', color: '#BE1622', fontWeight: '700' }}>{restData ? restData.NAME[lang] ? restData.NAME[lang] : restData.NAME.en : null}</h2>
                                                                        <p className="header_p  m-0" style={{ fontSize: '12px', color: '#383838', fontWeight: '500' }}>{restData ? restData.RESTAURANT_TYPE[lang] ? restData.RESTAURANT_TYPE[lang] : restData.RESTAURANT_TYPE.en : null}</p>
                                                                </div>
                                                        </Link>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                        <Link to={(slug != undefined) ? `${slug}/cart` : '/'} onClick={() => setNotification(slug)} style={{ position: 'relative', textDecoration: 'none' }}>
                                                                <img src="/images/carrello.png" alt="text" style={{ width: '40px', height: 'auto', marginRight: '10px' }} />
                                                                <span className="cart-count"> {cartData.totalItem}</span>
                                                        </Link>
                                                        <div className="tp_flg_tgl d-flex align-items-end relative">
                                                                <p onClick={() => handleCountryShow()} className="px-2 m-0" style={{ position: 'relative', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                                        <CircleFlag countryCode={(countryCode == 'en') ? 'gb' : countryCode} style={{ border: '2px solid #000', borderRadius: '100%', width: '22px', height: 'auto' }} />
                                                                        <span style={{ fontSize: '20px', color: '#000' }}><FaCaretDown /></span>
                                                                </p>
                                                                <ul className={show ? "flags_header" : 'd-none'}>
                                                                        <li><CircleFlag countryCode="it" style={{ width: '25px', height: 'auto', cursor: 'pointer' }} onClick={() => onChangeLang('it')} /></li>
                                                                        <li><CircleFlag countryCode="gb" style={{ width: '25px', height: 'auto', cursor: 'pointer' }} onClick={() => onChangeLang('en')} /></li>
                                                                        <li><CircleFlag countryCode="es" style={{ width: '25px', height: 'auto', cursor: 'pointer' }} onClick={() => onChangeLang('es')} /></li>
                                                                        <li><CircleFlag countryCode="fr" style={{ width: '25px', height: 'auto', cursor: 'pointer' }} onClick={() => onChangeLang('fr')} /></li>
                                                                        <li><CircleFlag countryCode="de" style={{ width: '25px', height: 'auto', cursor: 'pointer' }} onClick={() => onChangeLang('de')} /></li>
                                                                </ul>
                                                        </div>
                                                </div>

                                        </div>

                                </Row>

                        </div>
                </>
        )
}

export default Header;