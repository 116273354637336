import React from "react";
import { Form } from 'react-bootstrap';
import { Store } from "react-notifications-component";

const renderField = field => (
    <Form.Control type={field.type} placeholder={field.label}  {...field.input} />
)

const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid Email Address' : undefined;

const required = value => value ? undefined : 'required';

function notification(title, msg, type){
    Store.addNotification({
        title: title,
        message: msg,
        type: type,
        container: 'top-center',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000
        }
      })
}

export {
    renderField, email, required, notification
}

