import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLangCode, setLangCode } from './services/services';
import Routers from "./Routers";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReactNotifications } from 'react-notifications-component'
import { LANGUAGE } from "./constant/constants";
import { data } from "./components/Language";

function App() {
  const dispatch = useDispatch();
  let payload = getLangCode();
  let restData = useSelector((state) => state.restaurant.restbyid);
  let lang = useSelector(state => state.language.lang);
  const [title, setTitle] = useState(restData ? restData.NAME[lang] ? restData.NAME[lang] : restData.NAME.en : "User")
  useEffect(() => {
    if (payload != undefined && payload != '' && payload != null) {
      dispatch({ type: LANGUAGE, payload });
    } else {
      let payload = 'it';
      setLangCode(payload);
      dispatch({ type: LANGUAGE, payload });
    }
  }, [payload]);

  useEffect(() => {
    if (restData != undefined) {
      setTitle(restData.NAME[lang] ? restData.NAME[lang] : restData.NAME.en);
      document.title = `${title} - ${data.TITLE[lang] ? data.TITLE[lang] : 'End'}`;
      let content = `${data.META_CONTENT[lang]?data.META_CONTENT[lang]:data.META_CONTENT.en} ${restData.NAME[lang] ? restData.NAME[lang] : restData.NAME.en}.  ${data.META_CONTENT_LAST[lang]?data.META_CONTENT_LAST[lang]:data.META_CONTENT_LAST.en}`;
      document.querySelector('meta[name="description"]').content = content;
    }
  }, [title, restData])
  return (
    <>
      <ReactNotifications />
      <Routers />
    </>
  );
}

export default App;
