import { Modal, Button } from "react-bootstrap";

const BootstrapModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.heading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.component}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default BootstrapModal;