import { APP_LOAD, APP_LOAD_TOKEN, PROFILE_IMAGE, CURRENT_LOCATION, SLUG, HEADER } from "../constant/constants";

export default (state = '', action) => {
    switch (action.type) {
        case APP_LOAD:
            {
                return {
                    state,
                    token: action.payload.token,
                    user: action.payload.user
                }
            }
        case APP_LOAD_TOKEN:
            {
                return {
                    state,
                    token: action.payload.token
                }
            }
        case PROFILE_IMAGE:
            {
                return {
                    profile: action.payload.data
                }
            }
        case CURRENT_LOCATION:
            return{
                location: action.payload
            }
        case SLUG:
            return{
                slug: action.payload
            }
        case HEADER:
            return{
                header: action.payload
            }

        default:
            return state
    }
}