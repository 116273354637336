import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import { ADD_TO_CART, REMOVE_FROM_CART, RESTAURANT_BY_ID, RESET_CART, SLUG } from "../constant/constants";
import { FaEuroSign, FaWhatsapp, FaRegPaperPlane, FaFacebookMessenger, FaPlus, FaMinus } from "react-icons/fa";
import { getItemById, createCart, getCart, getTotalItem, AddItemInCart, setTotalItem, getRestaurantSlug, getRestaurantById } from "../services/services";
import BootstrapModal from "./BootstrapModal";
import CheckoutForm from "../validation/CheckoutForm";
import { Link, useParams } from "react-router-dom";
import { notification } from "../services/utileServices";
import Loader from "react-js-loader";
import { TelegramShareButton, WhatsappShareButton, FacebookMessengerShareButton, } from "react-share";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import { data } from "./Language";

const Cart = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const mainCartData = useSelector((state) => state.cartReducer.cart);
    const restData = useSelector(state => state.restaurant.restbyid);
    let lang = useSelector(state => state.language.lang);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(1);
    let { slug } = useParams();

    let [seatNo, setSeatNumber] = useState(0);



    useEffect(() => {
        const getRestdata = async (slug) => {
            await getRestaurantSlug(slug).then(result => {
                if (result.data && result.data.success) {
                    let payload = {};
                    payload = result.data;
                    dispatch({ type: RESTAURANT_BY_ID, payload });
                    let check = 0;
                    if (mainCartData && mainCartData != '' && mainCartData != undefined) {
                        for (let item of mainCartData) {
                            if (item.RESTAURANT_ID != result.data.data.RESTAURANT_ID) {
                                check++;
                            } else {
                                check = 0;
                            }
                        }

                    }
                    if (check) {
                        console.log('check cart');
                        setTotalItem(0);
                        createCart(0);
                        dispatch({ type: RESET_CART });
                    }
                    setLoader(0);
                } else {
                    notification('Not Found', result.data.error, 'warning');
                    setLoader(0);
                }
            }).catch(err => {
                notification('Api Error', 'Error in Api call ' + err.message, 'danger');
                setLoader(0);
            })
        }

        if (restData != '' && restData != undefined && restData != null) {
            setLoader(0);
        } else {
            getRestdata(slug);
        }

        if (slug != undefined) {
            let payload = slug;
            dispatch({ type: SLUG, payload });
        }


    }, [restData]);

    const onHandleAddToCart = useCallback(async (id) => {
        await getItemById(id).then(result => {
            if (result.data && result.data.success) {
                let product = {};
                product = result.data.data[0];

                let payload = AddItemInCart(product);
                dispatch({ type: ADD_TO_CART, payload: payload.data, totalItem: payload.totalItem })
                notification(data.ITEM_ADDED[lang], data.ITEM_ADDED_IN_CART[lang], 'success');

            }
        }).catch(err => {
            notification('Api Error', 'Error in Get item by id Api ' + err.message, 'danger')
        });


    })

    const removeHandler = (id) => {
        const ItemID = id;
        const cart = JSON.parse(getCart());
        if (!cart) {
            console.log("Something unexpected happen while removing Item");
        }
        else {
            let payload;
            let newTotalItem = getTotalItem();
            for (let i = 0; i < cart.length; ++i) {
                if (cart[i].ITEM_ID == ItemID) {
                    let deletingItem = cart[i];
                    if (deletingItem.quantity == 1) {
                        cart.splice(i, 1);
                    } else if (deletingItem.quantity > 1) {
                        cart[i].quantity = cart[i].quantity - 1;
                    }
                    payload = cart;
                    if (newTotalItem > 0) {
                        --newTotalItem;
                    }
                    createCart(JSON.stringify(cart));
                    setTotalItem(newTotalItem);
                }
            }
            if (payload) {
                notification(data.ITEM_REMOVED[lang], data.ITEM_REMOVED_FROM_CART[lang], 'danger');
                dispatch({ type: REMOVE_FROM_CART, payload: payload, totalItem: newTotalItem });
            } else {
                console.log("Removing Item Not found");
            }
        }
    }

    let totalPrice = 0;
    let items = (restData && restData.MOBILE_NUMBER != '' && restData.MOBILE_NUMBER != null) ? `${data.ITEM_DETAILS[lang]}:\n ` : `${data.ITEM_DETAILS[lang]}:\n`;
    if (mainCartData != undefined) {
        mainCartData.map((item, key) => {
            totalPrice += item.PRICE * item.quantity;
            let qt = 0;
            qt += item.quantity;
            if (restData && restData.MOBILE_NUMBER != '' && restData.MOBILE_NUMBER != null) {
                items += ` ${key + 1}. ${item.ITEM_NAME[lang]}\n ${data.QUANTITY[lang]} = ${qt}\n ${data.PRICE[lang]} = ${item.PRICE} EUR \n\n`;
            } else {
                items += `${key + 1}. ${item.ITEM_NAME[lang]}\n${data.QUANTITY[lang]} = ${qt}\n${data.PRICE[lang]} = ${item.PRICE} EUR\n\n`;
            }
        })
    }

    var url = "";
    if (restData && restData.MOBILE_NUMBER != '' && restData.MOBILE_NUMBER != null) {
        url = `${data.RESTAURANT_NAME[lang]} : ${restData.NAME[lang]}\n\n ${items}${data.TOTAL_PRICE[lang]} = ${totalPrice.toFixed(2)} EUR\n ${data.SEAT_NO[lang]} = ${seatNo}`;
    }else if(restData && restData.NAME){
        url = `${data.RESTAURANT_NAME[lang]} : ${restData.NAME[lang]}\n\n${items}${data.TOTAL_PRICE[lang]} = ${totalPrice.toFixed(2)} EUR\n${data.SEAT_NO[lang]} = ${seatNo}`;
    }

    console.log(url);

    const showAlert = () => {
        if (seatNo == '' || seatNo == 0) {
            notification('', data.SEAT_NUMBER[lang], 'warning');
        }
    }
    const handleShowModal = () => {
        if (seatNo == '' || seatNo == 0) {
            notification('', data.SEAT_NUMBER[lang], 'warning');
        } else {
            setModalShow(true);
        }
    }

    return (
        <>
            {!loader ?
                <div className="main_cart_section" style={{ paddingTop: '80px' }}>
                    {/*Header Section */}



                    <div className="acc_logo_head" style={{ minHeight: '93px', padding: '0', background: '#be1823' }}>
                        <div className="header_image" style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
                            {(restData && restData.IMG_LOGO) ? <img className="" style={{ width: '130px', height: 'auto' }} alt="text" src={(restData && restData.IMG_LOGO) ? `${process.env.REACT_APP_FRONT_END_API_URL + restData.IMG_LOGO}` : "/images/thumb.png"} /> : <img className="rounded-pill" style={{ width: '70px', height: '70px', boxShadow: '0 0 8px #9f9d9da8' }} alt="text" src="/images/thumb.png" />}
                        </div>
                    </div>


                    {/*Second section */}
                    <Row className="m-0 p-0 pb-1" style={{ backgroundColor: '#ffffff' }}>
                        <div className="d-flex align-items-center justify-content-between m-0 pt-3 pb-4 px-3">
                            <h3 style={{ fontSize: '22px', fontWeight: '800' }}>{data.CART_TEXT_F[lang]}</h3>
                            <p style={{ fontSize: '18px', fontWeight: '500' }}>{data.CART_TEXT_S[lang]}</p>

                        </div>
                        {
                            mainCartData ? mainCartData.map((element, key) =>
                                <div key={key} className="d-flex align-items-start justify-content-between px-3 mb-4">
                                    <div className="image_side_01 p-0">
                                        <img alt="item-image" style={{ width: '100%', height: '80px', borderRadius: '12px', boxShadow: '0 0 1px #000' }} src={(element.IMG_ITEM != '') ? `${process.env.REACT_APP_FRONT_END_API_URL + element.IMG_ITEM}` : '/demo.png'} />
                                    </div>
                                    <div className="px-3 content_side_02">
                                        <h5 style={{ fontSize: '20px', fontWeight: '700', color: '#000' }}> {element.ITEM_NAME[lang] ? element.ITEM_NAME[lang] : element.ITEM_NAME.en} </h5>
                                        <p className="mb-2" style={{ fontSize: '16px', color: '#645f5f', lineHeight: '1.1' }}> {element.DESCRIPTION[lang] ? element.DESCRIPTION[lang] : element.DESCRIPTION.en} </p>
                                    </div>
                                    <div className="numeric_side" style={{ textAlign: 'right' }}>
                                        <p style={{ color: '#BE1622', fontWeight: '800', fontSize: '25px' }} className=""> {element.PRICE} <span style={{ fontSize: '18px' }}><FaEuroSign /></span></p>
                                        {/* increament/decrement num */}
                                        <ul className="p-0 d-flex align-items-center">
                                            <li onClick={() => removeHandler(element.ITEM_ID)} className="btn_incre"> <FaMinus /> </li>
                                            <li className="btn_incre_number">{element.quantity}</li>
                                            <li onClick={() => { onHandleAddToCart(element.ITEM_ID) }} className="btn_incre"> <FaPlus /> </li>
                                        </ul>
                                    </div>

                                </div>
                            ) : null
                        }

                    </Row>

                    {/*Total section */}
                    <div className="d-flex align-items-center justify-content-between px-3 pt-3 pb-3" style={{ backgroundColor: 'rgb(245,246,246)' }}>
                        <h4 className="m-0" style={{ fontWeight: '700' }}>{data.CART_TEXT_TOTAL[lang]}</h4>
                        <p className="m-0" style={{ fontWeight: '800', fontSize: '18px' }} >{totalPrice.toFixed(2)}<span style={{ fontWeight: '800' }} ><FaEuroSign /></span></p>
                    </div>




                    {/*Icons section */}
                    <div className="pt-3 pb-4" style={{ backgroundColor: '#ffffff' }}>
                        <p style={{ fontSize: '16px', fontWeight: '700', textAlign: 'center' }}>{restData ? restData.TEXT_SHORT[lang] ? restData.TEXT_SHORT[lang] : restData.TEXT_SHORT.en : null}</p>
                        <div className="py-4 d-flex align-item-center justify-content-center">
                            <p className="me-3">{data.SEAT_NUMBER[lang]}</p>
                            <input type="number" className="text-center" style={{ width: '120px' }} placeholder={seatNo} onChange={(e) => setSeatNumber(e.target.value)} />
                        </div>
                        <p className="pt-4 pb-0 m-0" style={{ fontWeight: '500', textAlign: 'center', color: '#be1622' }}>{data.CART_TEXT_T[lang]}</p>
                        <p className="pb-4" style={{ textAlign: 'center', fontSize: '42px' }}>
                            {
                                (restData && restData.MOBILE_NUMBER != '' && restData.MOBILE_NUMBER != null) ?
                                    <a href={(seatNo == '' || seatNo == 0) ? "javascript:void(0)" : encodeURI(`https://wa.me/${restData.MOBILE_NUMBER}?text=${url}`)} style={{ color: (seatNo == '' || seatNo == 0) ? 'rgb(51 51 51 / 65%)' : 'rgb(16 15 15)' }}><span className="px-2" style={{ zIndex: '999' }} onClick={() => showAlert()}><FaWhatsapp /></span></a>
                                    : <WhatsappShareButton url={url} disabled={(seatNo == '' || seatNo == 0) ? true : false}><span className="px-2" onClick={() => showAlert()}><FaWhatsapp /></span></WhatsappShareButton>
                            }
                            {/* <a href={`tg://msg?text=${url}&to=${restData.MOBILE_NUMBER}`} style={{color:'#333'}} ><span className="px-2" onClick={() => showAlert()}><FaRegPaperPlane /></span></a> */}
                            <TelegramShareButton url={url} disabled={(seatNo == '' || seatNo == 0) ? true : false}><span className="px-2" onClick={() => showAlert()}><FaRegPaperPlane /></span></TelegramShareButton>
                        </p>
                        <div className="px-3">
                            <Link to={restData ? `/${restData.SLUG}/menu` : null}><button className="torna_btn mb-3" style={{ fontSize: '16px', padding: '10px' }}>{data.CART_TEXT_BUTTON_MENU[lang]}</button></Link>
                            <button className={(mainCartData != 0) ? " bgred_btn" : "d-none"} onClick={() => handleShowModal()} style={{ fontSize: '16px', padding: '10px' }} >{data.ORDER_ONLINE[lang]}</button>
                        </div>

                    </div>
                    <BootstrapModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        heading={data.ORDER_ONLINE[lang]}
                        component={<CheckoutForm onClose={() => setModalShow(false)} totalPrice={totalPrice} seatNo={seatNo} />}
                    />
                </div>
                : <Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} />
            }

            <Footer />
        </>
    )
}
export default Cart;
