

export const data = {
    MENU_CATEGORY_NOT_FOUND:{en:'No Categories Found',it:'Nessuna categoria trovata',fr:'Aucune catégorie trouvée', es:'No se encontraron categorías', de:'Keine Kategorien gefunden'}, 
    MENU_ITEM_NOT_AVAIL:{en:'Item Not Available',it:'Articolo non disponibile',fr:'Objet non disponible', es:'Artículo no disponible', de:'Objekt nicht verfügbar'}, 
    MENU_TEXT_CAT_NOT_FOUND:{en:'No Categories Found',it:'Nessuna categoria trovata',fr:'Aucune catégorie trouvée', es:'No se encontraron categorías', de:'Keine Kategorien gefunden'}, 
    FOOTER_MADE_WITH:{en:'Made with',it:'Fatto con',fr:'Fabriqué avec', es:'Hecho con', de:'Gemacht mit'}, 
    MENU_TEXT_REST:{en:'Restaurant',it:'Ristorante',fr:'Restaurant', es:'Restaurante', de:'Restaurant'}, 
    SPLASH_TEXT_RESTO:{en:'',it:'',fr:'', es:'', de:''}, 
    SPLASH_TEXT_DA:{en:'DA 3 GENERATION',it:'DA 3 GENERAZIONE',fr:'DA 3 GÉNÉRATION', es:'DA 3 GENERACIÓN', de:'DA 3 GENERATION'}, 
    SPLASH_TEXT_BUTTON:{en:'DISCOVER THE MENU',it:'SCOPRI IL MENU',fr:'DÉCOUVRIR LA CARTE', es:'DESCUBRE EL MENÚ', de:'ENTDECKEN SIE DAS MENÜ'}, 
    SPLASH_TEXT_ROMA:{en:'ROMA',it:'ROMA',fr:'ROMS', es:'Roma', de:'Roma'}, 
    CART_TEXT_F:{en:'Cart',it:'Carrello',fr:'Chariot', es:'Carro', de:'Wagen'}, 
    CART_TEXT_S:{en:'Change orders',it:'Cambia ordini',fr:'Changer les commandes', es:'Cambiar ordenes', de:'Die Bestellung ändern'}, 
    CART_TEXT_TOTAL:{en:'Total',it:'Totale',fr:'Totale', es:'Total', de:'Gesamt'}, 
    CART_TEXT_T:{en:'Forward online with',it:'Inoltra ondine con',fr:'Transférer en ligne avec', es:'Reenviar en línea con', de:'Online weiterleiten mit'}, 
    CART_TEXT_BUTTON_MENU:{en:'BACK TO MENU',it:'TORNA AL MENU',fr:'RETOUR AU MENU', es:'RETORNO AL MENÚ', de:'RÜCKFAHRT AU MENÜ'}, 
    CART_TEXT_BUTTON_ORDER:{en:'Clear the list',it:'SVUOTA LA LISTA',fr:'EFFACER LA LISTE', es:'BORRAR LA LISTA', de:'LÖSCHEN SIE DIE LISTE'}, 
    ITEM_DETAILS_TEXT_NO_AL:{en:'No Allergens Exists',it:'Non esistono allergeni',fr:"Aucun allergène n'existe", es:'No existen alérgenos', de:'Keine Allergene vorhanden'}, 
    ITEM_DETAILS_TEXT_F:{en:'Gluten, Milk and milk based products',it:'Glutine, Latte e prodotti a base di latte',fr:'Gluten, Lait et produits à base de lait', es:'Gluten, Leche y productos lácteos', de:'Gluten, Milch und Produkte auf Milchbasis'}, 
    MENU_ALLERGEN:{en:'Allergens',it:'Allergeni',fr:'Allergènes', es:'Alérgenos', de:'Allergene'}, 
    TITLE:{en:'Browse the digital menu',it:'Sfoglia il menu digitale',fr:'Parcourir le menu', es:'Navegar por el menú digital', de:'Durchsuchen Sie die digitale Speisekarte'}, 
    META_CONTENT:{en:'Visit the digital menu of',it:'Visita il menu digitale di',fr:'Visitez le menu numérique de', es:'Visita el menú digital de', de:'Besuchen Sie die digitale Speisekarte von'}, 
    META_CONTENT_LAST:{en:'Made by Scevoo.it!',it:'Realizzato con Scevoo.it!',fr:'Fabriqué avec Scevoo.it!', es:'Hecho con Scevoo.it!', de:'Gemacht mit Scevoo.it!'}, 
    FIRST_NAME:{en:'Name', it:'Nome', fr:'Nom', es:'Nombre', de:'Name'},
    SURNAME:{en:'Surname', it:'Cognome', fr:'Nom de famille', de:'Familien-oder Nachname', es:'Apellido'},
    EMAIL:{en:'EMAIL', it:'E-mail', fr:'E-mail', es:'Correo electrónico', de:'Email'},
    MOBILE_NUMBER:{en:'Phone Number', it:'Numero di telefono', fr:'Numéro de téléphone', es:'Número de teléfono', de:'Telefonnummer'},
    ORDER_ONLINE:{en:'Order online', it:'Ordina online', fr:'Commander en ligne', es:'Comprar online', de:'Online bestellen'},
    INSERT_YOUR_DATA:{en:'Insert your data', it:'Inserisci i tuoi dati', fr:'Insérez vos données', es:'Inserta tus datos', de:'Geben Sie Ihre Daten ein'},
    PROCEED_TO_CHECKOUT:{en:'Proceed to checkout', it:'Procedere al checkout', fr:'Passer à la caisse', es:'Pasar por la caja', de:'Zur Kasse'},
    RESTAURANT_NAME:{en:'Restaurant Name', it:'Nome del ristorante', fr:'Nom du restaurant', es:'Nombre del restaurante', de:'Name des Restaurants'},
    ITEM_DETAILS:{en:'Items Details', it:'Dettagli degli articoli', fr:'Détails des articles', es:'Detalles de los artículos', de:'Artikeldetails'},
    ITEM_NAME:{en:'Item Name', it:"Nome dell'oggetto", fr:"Nom de l'article", es:'Nombre del árticulo', de:'Artikelname'},
    QUANTITY:{en:'Quantity', it:"Quantità", fr:"Quantité", es:'Cantidad', de:'Menge'},
    PRICE:{en:'Price', it:"Prezzo", fr:"Prix", es:'Precio', de:'Preis'},
    TOTAL_PRICE:{en:'Total Price', it:"Prezzo totale", fr:"Prix total", es:'Precio total', de:'Gesamtpreis'},
    SEAT_NUMBER:{en:'Enter seat number', it:"Inserisci numero postazione", fr:"Entrez le numéro de siège", es:'Ingrese el número de asiento', de:'Platznummer eingeben'},
    ITEM_ADDED:{en:'Item Added', it:"Articolo aggiunto", fr:"Article ajouté", es:'Artículo agregado', de:'Artikel hinzugefügt'},
    ITEM_ADDED_IN_CART:{en:'Item successfully added', it:"Articolo aggiunto con successo", fr:"Article ajouté avec succès", es:'Artículo agregado con éxito', de:'Artikel erfolgreich hinzugefügt'},
    ITEM_REMOVED:{en:'Item removed', it:"Articolo rimosso", fr:"Article supprimé", es:'Elemento eliminado', de:'Artikel entfernt'},
    ITEM_REMOVED_FROM_CART:{en:'Item removed from cart successfully', it:"Articolo rimosso con successo", fr:"Article supprimé avec succès", es:'Artículo eliminado con éxito', de:'Artikel erfolgreich entfernt'},
    SEAT_NO:{en:'Seat Number', it:"Numero del posto", fr:"Numéro de siège", es:'Número de asiento', de:'Sitznummer'},

}