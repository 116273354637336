
import React from "react";
import { Col, Row, Container } from 'react-bootstrap';

export default (props) => {
    return (
        <main style={{height:'100vh'}}>
                <section className="vh-100 d-flex align-items-center justify-content-center">
                    <Container>
                        <Row>
                            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
                                <div>
                                    <img src='/images/404.svg' className="img-fluid w-75" />
                                    <h1 className="text-primary mt-5">
                                        Page not <span className="fw-bolder">found</span>
                                    </h1>
                                    <p className="lead my-4">
                                        Oops! Looks like you followed a bad link. If you think this is a
                                        problem with us, please tell us.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
        </main>
    );
};
