import { LANGUAGE } from "../constant/constants";

export default (state = '', action) => {
    switch (action.type) {
        case LANGUAGE:
            return{
                lang: action.payload
            }

        default:
            return state
    }
}