import React from "react-bootstrap";
import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { data } from "./Language";


const Footer = () => {

    let lang = useSelector(state => state.language.lang);
        
    return (
        <>
            <Container fluid className="vv">
                <Row>
                {/* borderBottom:'2px solid #bcbcbc' */}
                    <hr className="mb-4" />
                    <p className="d-flex align-items-baseline justify-content-center" style={{ fontWeight: '500', textAlign: 'center', fontSize: '13px' }}>{data.FOOTER_MADE_WITH[lang]}<img src="/images/logo-footer.png" alt="text" style={{ width: '80px', height: 'auto', marginLeft: '10px' }} /></p>
                </Row>
            </Container>
        </>
    )
}

export default Footer;
