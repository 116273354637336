import React, { useEffect, useCallback, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { createCart, getAllCategoryByRestId, setTotalItem, getRestaurantSlug } from "../services/services";
import { useSelector, useDispatch } from "react-redux";
import { CATEGORY_BY_REST_ID, LANGUAGE, RESET_CART, RESTAURANT_BY_ID, SLUG } from "../constant/constants";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "react-js-loader";
import { notification } from "../services/utileServices";
import { data } from "./Language";

const SplashScreen = () => {
    let dispatch = useDispatch();
    let restData = useSelector((state) => state.restaurant.restbyid);
    let lang = useSelector(state => state.language.lang);

    const mainCartData = useSelector((state) => state.cartReducer.cart);
    let { slug } = useParams();
    const [loader, setLoader] = useState(1);
    let history = useNavigate();
    const onGetCatByRestId = useCallback(async (slug) => {
        await getAllCategoryByRestId(slug).then(result => {
            if (result.data && result.data.success) {
                let payload = {};
                payload = result.data;
                dispatch({ type: CATEGORY_BY_REST_ID, payload });
            }
        }).catch(err => {
            notification('Api Error', 'Error in Get Category by Restaurant Id ' + err.message, 'danger');
        });

    })

    useEffect(() => {
        const onGetRestData = async (slug) => {
            await getRestaurantSlug(slug).then(result => {
                if (result.data && result.data.success) {
                    let payload = {};
                    payload = result.data;
                    dispatch({ type: RESTAURANT_BY_ID, payload });
                    let check = 0;
                    if (mainCartData && mainCartData != '' && mainCartData != undefined) {
                        for (let item of mainCartData) {
                            if (item.RESTAURANT_ID != result.data.data.RESTAURANT_ID) {
                                check++;
                            } else {
                                check = 0;
                            }
                        }

                    }
                    if (check) {
                        setTotalItem(0);
                        createCart(0);
                        dispatch({ type: RESET_CART });
                    }
                    setLoader(0);
                } else {
                    notification('Warning', 'Restaurant Does Not Exist', 'warning');
                    setLoader(0);
                    history('/');
                }
            }).catch(err => {
                notification('Api Error', 'Error in Get Restaurant by Slug ' + err.message, 'danger');
            });
        }
        if (restData == undefined || restData == "" || restData == null) {
            onGetRestData(slug);
        } else {
            setLoader(0);
        }
        if (slug != undefined) {
            let payload = slug;
            dispatch({ type: SLUG, payload });
        }
        document.getElementById("HeaderToggle").classList.add("d-none")
        return () => {
            document.getElementById("HeaderToggle").classList.remove("d-none")
        }
    }, [restData, slug, lang])

    if (restData && restData.SLUG != undefined && restData.SLUG != null && restData.SLUG != '' && restData.SLUG != slug) {
        history('/');
    }

    return (
        <>
            {!loader ?

                <div className="hostaria_bg w-full relative" style={{ paddingTop: '80px', backgroundImage: `url(${process.env.REACT_APP_FRONT_END_API_URL + restData.IMG_SPLASH_SCREEN})`, boxShadow: '0 810px 284px #00000085 inset' }}>
                   
                        <div style={{position:'absolute',top:'5%',left:'50%',transform:'translate(-50%)'}} >
                            <div className="d-flex align-items-center justify-content-center" style={{ fontSize: '60px', paddingBottom: '30px' }} >
                                <img style={{ width: '300px', height: 'auto' }} src={`${process.env.REACT_APP_FRONT_END_API_URL + restData.IMG_LOGO}`} alt="logo" />
                            </div>

                            <h1 className="text-center text-white pt-0" style={{ fontSize: '35px', letterSpacing: '1px', fontWeight: '700', textTransform: 'none' }}>{restData.NAME[lang] ? restData.NAME[lang] : restData.NAME.en}</h1>
                            <ul className="d-flex align-items-center justify-content-center p-0 mb-2">
                                <li style={{ color: '#ffffff', fontSize: '8px' }}><FaCircle /></li>
                                <li className="text-white px-2" style={{ fontSize: '22px', fontFamily: 'Yantramanav', lineHeight: '1', wordBreak: 'break-word' }}>{restData.RESTAURANT_TYPE[lang] ? restData.RESTAURANT_TYPE[lang] : restData.RESTAURANT_TYPE.en}</li>
                                <li style={{ color: '#ffffff', fontSize: '8px' }} ><FaCircle /></li>
                            </ul>
                        </div>
                        <div className="f_btn" style={{position:'absolute',top:'65%', left:'50%',transform:'translateX(-50%)'}}>
                            <Link to={`/${restData.SLUG}/menu`}><button className="menu_btn" style={{ fontSize: '18px', fontWeight: '700', padding:'12px 23px', width:'250px' }} onClick={() => onGetCatByRestId(restData.SLUG)}>{data.SPLASH_TEXT_BUTTON[lang]}</button></Link>
                        </div>
                        <p className="text-white d-flex align-items-baseline justify-content-center pb-3 mt-5" style={{ fontWeight: '500', textAlign: 'center', fontSize: '13px',position:'absolute',bottom:'0%', left:'50%',transform:'translateX(-50%)' }}>
                            <span>{data.FOOTER_MADE_WITH[lang]}</span>
                            <img src="/images/white-logo.png" alt="text" style={{ width: '80px', height: 'auto', marginLeft: '10px' }} />
                            </p>
                    
                </div> : <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} /></div>
            }
        </>
    )

}

export default SplashScreen;