import { CATEGORY_ITEM, SINGLE_ITEM } from "../constant/constants";

export default (state = '', action) =>{
    switch(action.type){
        case CATEGORY_ITEM:
            return{
                state,
                catItem: action.payload.data,
            }
        case SINGLE_ITEM:
            return{
                state,
                singleitem: action.payload.data[0],
            }
        default:
            return state
    }
}