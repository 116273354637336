import { RESTAURANT_BY_ID } from "../constant/constants";

export default (state = '', action) =>{
    switch(action.type){
        case RESTAURANT_BY_ID:
            return{
                state,
                restbyid: action.payload.data,
            }
        default:
            return state
                    
    }
}