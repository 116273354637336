
import { ADD_TO_CART, REMOVE_FROM_CART, RESET_CART } from "../constant/constants";

let INITIAL_STATE = {
    cart : [],
    totalItem:0
};

if(localStorage.getItem('scevoo_cart')){
    INITIAL_STATE.cart = JSON.parse(
        localStorage.getItem('scevoo_cart')
    );
    INITIAL_STATE.totalItem = JSON.parse(localStorage.getItem('total_item'));
}

const cartReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case ADD_TO_CART : 
            return{
                cart : action.payload,
                totalItem: action.totalItem
            }
        case REMOVE_FROM_CART : 
            return {
                cart : [...action.payload],
                totalItem : action.totalItem
            };
        case RESET_CART : 
            return {
                cart : [],
                totalItem :0
            };    
        default : 
                return state;
    }
}

export default cartReducer;