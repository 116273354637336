import React from 'react';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Cart from "./components/Cart.jsx"
import AccordionScreen from './components/AccordionScreen';
import Header from "./components/Header";
import SplashScreen from './components/SplashScreen';
import NotFound from './validation/NotFound.jsx';
import Home from './components/Home';
import ItemsDetails from './components/ItemsDetail.jsx';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

const Routers = () => {

  return (
    <Router>
       <div className='View_point'>
         
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/:slug" element={<SplashScreen />} />
        <Route exact path="/:slug/menu" element={<AccordionScreen />} />
        <Route exact path="/:slug/cart" element={<Cart />} />
        <Route exact path="/:slug/item/:id" element={<ItemsDetails />} />
        <Route path='/404' element={<NotFound />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      </div>
    </Router>
    
  )
}

export default Routers;
