import { combineReducers } from "redux";
import { routerReducer } from 'react-router-redux';
import {reducer as formReducer} from 'redux-form';
import cartReducer from "./cartReducer";
import restaurant from "./restaurant";
import item from "./item";
import app from "./app";
import category from "./category";
import allergens from "./allergens";
import language from "./language";

export default combineReducers({
    cartReducer,restaurant,item,app,category, allergens, language,
    router: routerReducer,
    form: formReducer
})