import React, { useEffect } from "react";
import { FaGraduationCap, FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Home = () => {
    let history = useNavigate();
    let restData = useSelector(state => state.restaurant.restbyid);

    useEffect(() => {
        window.location.href = "https://www.scevoo.it";
        if (restData && restData.RESTAURANT_ID) {
            history(`/${restData.SLUG}`);
        }
    }, [restData])  

return (
    <>
        <div className="hostaria_bg">
            <div className="content_name pt-4">
                <ul className="d-flex align-items-center justify-content-center p-0">
                    <li className="" style={{ color: '#f7f7f7', fontSize: '14px', fontWeight: '100px' }}>ROMA</li>
                    <li className="d-flex align-items-center text-white px-5" style={{ fontSize: '60px', paddingTop: '0px' }}  ><FaGraduationCap /></li>
                    <li className="" style={{ color: '#f7f7f7', fontSize: '14px', fontWeight: '100px' }}>1986</li>
                </ul>
                <h1 className="text-center text-white pt-0" style={{ fontSize: '35px', letterSpacing: '3px', fontWeight: '700' }}>SCEVOO</h1>
                <ul className="d-flex align-items-center justify-content-center p-0 m-0">
                    <li style={{ color: '#ffffff', fontSize: '8px' }}><FaCircle /></li>
                    <li className="text-white text-center px-2" style={{ fontSize: '30px', fontFamily: 'Yantramanav' }}>ROMANA ENRICO</li>
                    <li style={{ color: '#ffffff', fontSize: '8px' }} ><FaCircle /></li>
                </ul>
                <p className="text-center text-white" style={{ fontSize: '12px', color: '#f7f7f7' }}>DA 3 GENERATION<br />
                    CUCINA TIPICA-PASTA FATTA IN CASA. PIZZERIA
                </p>
                <div className="f_btn d-flex align-items-center justify-content-center py-5">
                    <button className="menu_btn" style={{ fontSize: '18px', fontWeight: '700' }}>SCOPRI IL MENU</button>
                </div>
                <div className="text-center text-white pt-5 pb-3 px-5">
                    <p className="d-flex align-items-baseline justify-content-center" style={{ fontWeight: '500', textAlign: 'center', fontSize: '13px' }}>Made With<img src="/images/white-logo.png" alt="text" style={{ width: '80px', height: 'auto', marginLeft: '10px' }} /></p>
                </div>
            </div>
        </div>
    </>
)
}

export default Home;