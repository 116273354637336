import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import { FaEuroSign, FaPlay, FaChevronDown } from "react-icons/fa";
import { CATEGORY_ITEM, RESTAURANT_BY_ID, SINGLE_ITEM, ADD_TO_CART, SLUG, RESET_CART, ALLERGEN_BY_ITEM_ID, CATEGORY_BY_REST_ID } from "../constant/constants";
import { getItemByCatId, getSingleItem, getAllCategoryByRestId, getCart, createCart, getAllergensByItemId, getTotalItem, setTotalItem, getItemById, getRestaurantSlug, AddItemInCart } from "../services/services";
import Loader from "react-js-loader";
import { useParams, useNavigate } from 'react-router-dom'
import { notification } from "../services/utileServices";
import Footer from "./Footer";
import { data } from "./Language";

const AccordionScreen = () => {
  const category = useSelector((state) => state.category.catbyid);
  const catItem = useSelector((state) => state.item.catItem);
  let allAllergens = useSelector(state => state.allergens.allergenbyid);
  const restData = useSelector(state => state.restaurant.restbyid);
  const mainCartData = useSelector((state) => state.cartReducer.cart);
  let lang = useSelector(state => state.language.lang);
  
  const [fetchingData, setFetchingData] = useState(0);
  const [loader, setLoader] = useState(1);
  const [restid, setRestId] = useState('');
  const dispatch = useDispatch();

  // Toogling to show Item data is fetching right now
  const handleFetchingData = () => {
    setFetchingData(0);
  }

  let history = useNavigate();
  // Here Fetching Categories Item when clicked on Category
  const handleOnGetCatItem = useCallback(async (cid, restid) => {
    await getItemByCatId(cid, restid).then(result => {
      if (result.data && result.data.success) {
        let payload = {};
        payload = result.data;
        dispatch({ type: CATEGORY_ITEM, payload });
        setFetchingData(1);
      } else {
        let payload = {};
        dispatch({ type: CATEGORY_ITEM, payload });
        setFetchingData(1);
      }
    }).catch(err => {
      notification('Network Error', 'Error in Get Item by category id ' + err.message, 'danger');
    });

  }, []);
  let [toogleAddToCartLoader, setToogleAddToCartLoader] = useState(undefined);
  // Adding Item is a Cart
  const onHandleAddToCart = useCallback(async (id) => {
    setToogleAddToCartLoader(id);
    await getItemById(id).then(result => {
      if (result.data && result.data.success) {
        let product = {};
        product = result.data.data[0];

        let payload = AddItemInCart(product);
        dispatch({ type: ADD_TO_CART, payload: payload.data, totalItem: payload.totalItem })
        notification(data.ITEM_ADDED[lang], data.ITEM_ADDED_IN_CART[lang], 'success');
        setToogleAddToCartLoader(undefined);
      }
    }).catch(err => {
      setToogleAddToCartLoader(undefined);
      notification('Network Error', 'Error in Get Item ' + err.message, 'danger');
    });

  })


  const [show, toggleShow] = useState(false);
  let [id, setIds] = useState(0)

  const handleState = (ids) => {
    toggleShow(!show);
    setIds(id = ids);
  }

  // Here Getting allergens by Item It and then dispatching it in store
  const handleGetAllergenByItem = useCallback(async (itemId, rest_id) => {
    setLoader(1);
    let payload = {data:''};
    dispatch({ type: ALLERGEN_BY_ITEM_ID, payload });
    await getAllergensByItemId(itemId, rest_id).then(result => {
      if (result.data && result.data.success) {
        let payload = {};
        payload = result.data;
        dispatch({ type: ALLERGEN_BY_ITEM_ID, payload });
        setLoader(0);
      } else {
        setLoader(0);
        dispatch({ type: ALLERGEN_BY_ITEM_ID, payload: { data: undefined } })
      }
    }).catch(err => {
      setLoader(0);
      notification('Network Error', 'Error in Get Allergen ' + err.message, 'danger');
    });

  })


  let { slug } = useParams();

  useEffect(() => {
    const onGetRestData = async (slug) => {
      await getRestaurantSlug(slug).then(result => {
        if (result.data && result.data.success) {
          let payload = {};
          payload = result.data;
          setRestId(payload.data.RESTAURANT_ID);
          dispatch({ type: RESTAURANT_BY_ID, payload });
          let check = 0;
          if (mainCartData && mainCartData != '' && mainCartData != undefined) {
            for (let item of mainCartData) {
              if (item.RESTAURANT_ID != result.data.data.RESTAURANT_ID) {
                check++;
              } else {
                check = 0;
              }
            }

          }
          if (check) {
            setTotalItem(0);
            createCart(0);
            dispatch({ type: RESET_CART });
          }
        } else {
          notification('No data', result.data.error, 'warning');
          history('/');
        }
      }).catch(err => {
        notification('Network Error', 'Error in Get Restaurant ' + err.message, 'danger');
        history('/');
      })

    }

    const onGetCatByRestId = async (slug) => {
      await getAllCategoryByRestId(slug).then(result => {
        if (result.data && result.data.success) {
          let payload = {};
          payload = result.data;
          dispatch({ type: CATEGORY_BY_REST_ID, payload });
        }
      }).catch(err => {
        notification('Network Error', 'Error in Get Category ' + err.message, 'danger');
      });
    }

    if (restData === undefined) {
      onGetRestData(slug);
    }
    if (category === undefined) {
      onGetCatByRestId(slug);
    }

    if (slug != undefined) {
      let payload = slug;
      dispatch({ type: SLUG, payload });
    }
    

  }, [restData, category]);

  const onGetSingleItem = useCallback(async (id) => {
    await getSingleItem(id).then(result => {
      if (result.data.success) {
        let payload = {};
        payload = result.data;
        dispatch({ type: SINGLE_ITEM, payload });
        console.log("item fetch successfully");
        history(`/${slug}/item/${payload.data[0].ITEM_ID}`);
      } else {
        console.log("No item Found");
        history('/')
      }
    }).catch(err => {
      console.log("error in api " + err.message);
      history('/')
    })
  })

  if (restData && restData.SLUG != undefined && restData.SLUG != slug) {
    history('/');
  }

  return (
    <>

      <div className="main_acc_section" style={{ paddingTop: '80px'}}>

        <div className="acc_logo_head"  style={{ minHeight: '93px',padding:'0',background:'#be1823' }}>
          <div className="header_image" style={{ position: 'absolute', left: '50%', top:'50%',transform:'translate(-50%,-50%)' }}>
            {(restData && restData.IMG_LOGO) ? <img className="" style={{ width: '130px', height: 'auto' }} alt="text" src={(restData && restData.IMG_LOGO) ? `${process.env.REACT_APP_FRONT_END_API_URL + restData.IMG_LOGO}` : "/images/thumb.png"} /> : <img className="rounded-pill" style={{ width: '70px', height: '70px', boxShadow: '0 0 8px #9f9d9da8' }} alt="text" src="/images/thumb.png" />}
          </div>
          {/* <div className="header_content py-4" style={{ minHeight: '93px' }}>
            <h2 className="header_h2 text-center m-0" style={{ fontSize: '20px', color: '#BE1622', fontWeight: '700' }}>{restData ? restData.NAME[lang]?restData.NAME[lang]:restData.NAME.en : null}</h2>
            <p className="header_p text-center m-0" style={{ fontSize: '12px', color: '#383838', fontWeight: '500' }}>{restData ? restData.RESTAURANT_TYPE[lang]?restData.RESTAURANT_TYPE[lang]:restData.RESTAURANT_TYPE.en : null}</p>
          </div> */}
        </div>

        <Accordion >

          {
            category ?
                 ( category != '') ? category.map((cat, key) =>
                    <Accordion.Item eventKey={key} key={key} className="mb-1" style={{ backgroundColor: '#fafafa' }}>
                      <Accordion.Header className="d-flex align-items-center justify-content-between" onClick={() => { handleFetchingData(); handleOnGetCatItem(cat.CATEGORY_ID, cat.RESTAURANT_ID); }} ><span>{cat.CATEGORY_NAME[lang]?cat.CATEGORY_NAME[lang]:cat.CATEGORY_NAME.en}</span> <span style={{ color: '#BE1622' }}><FaChevronDown /></span></Accordion.Header>

                      <Accordion.Body style={{ padding: '10px 15px' }}>
                        {fetchingData ?
                            (catItem != undefined && catItem.length > 0)? catItem.map((item, key) =>
                              <div className={(item.AVAILABLE == 1) ? "card acc_card w-full mt-2" : 'd-none'} key={key}>
                                <div className="d-flex align-items-top justify-content-between">
                                  <div className="image_side">
                                    <img alt="Menu item" style={{ width: '100%', height: '100px', borderRadius: '10px', boxShadow: '0 0 1px #000', cursor: 'pointer' }} src={`${item.IMG_ITEM?process.env.REACT_APP_FRONT_END_API_URL + item.IMG_ITEM:'/demo.png'}`} onClick={() => { onGetSingleItem(item.ITEM_ID); handleGetAllergenByItem(item.ITEM_ID, restData.RESTAURANT_ID) }} />
                                  </div>
                                  <div className="content_side">
                                    <h5 onClick={() => { onGetSingleItem(item.ITEM_ID); handleGetAllergenByItem(item.ITEM_ID, restData.RESTAURANT_ID) }} style={{ fontSize: '16px', fontWeight: '700', color: '#000', lineHeight: '1', cursor: 'pointer' }}> {item.ITEM_NAME[lang]?item.ITEM_NAME[lang]:item.ITEM_NAME.en} </h5>
                                    <p className="mb-2" style={{ fontSize: '14px', color: '#645f5f', lineHeight: '1.1' }}> {item.DESCRIPTION[lang]?item.DESCRIPTION[lang]:item.DESCRIPTION.en} </p>

                                    <p style={{ color: '#BE1622', fontWeight: '700' }} className=" d-flex align-items-center mb-3"> {item.PRICE} <span><FaEuroSign /></span></p>
                                  </div>
                                </div>
                                <hr className="mb-1 mt-2" />
                                <div className="p-0" style={{ position: 'relative' }}>
                                  <div className="mb-1 d-flex align-items-center justify-content-between" style={{ fontSize: '14px', color: '#000', fontWeight: '600', cursor: 'pointer', height: '35px' }}>
                                    <span onClick={() => { handleState(item.ITEM_ID); handleGetAllergenByItem(item.ITEM_ID, restData.RESTAURANT_ID) }} >{data.MENU_ALLERGEN[lang]}<FaPlay style={{ transform: (show === true && id === item.ITEM_ID) ? 'rotate(92deg)' : 'rotate(0deg)', fontSize: '8px', marginLeft: '4px' }} /></span>
                                    <span style={{ color: '#BE1622', fontSize: '24px' }} className="item-details-loader"> {toogleAddToCartLoader === item.ITEM_ID ? <Loader type="spinner-default" bgColor={"#BE1622"} color={'#BE1622'} size={50} />
                                      : <img src="/aggiungi.svg" alt="text" style={{ width: '40px', height: 'auto' }} onClick={() => onHandleAddToCart(item.ITEM_ID)} />} </span></div>
                                  {
                                    (show === true && id === item.ITEM_ID) ?
                                      !loader ?
                                        ((allAllergens && allAllergens.length) ? (
                                          allAllergens.map((allergen, key) => <span> {allergen.ALLERGEN[lang]?allergen.ALLERGEN[lang]:allergen.ALLERGEN.en}, </span>)
                                        ) : <p className="mb-2" key={key} style={{ fontSize: '13px' }}> {data.ITEM_DETAILS_TEXT_NO_AL[lang]} </p>) : <div className="allergen-loader"><Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={40} /></div> : null
                                  }
                                </div>
                              </div>
                            ) : <span>{data.MENU_ITEM_NOT_AVAIL[lang]}</span>
                           : <Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} />
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : <div>{data.MENU_CATEGORY_NOT_FOUND[lang]}</div>
              
              : <Loader type="bubble-scale" bgColor={"#BE1622"} color={'#BE1622'} size={100} />
          }

          {/*first accordion end */}
          {/*Ten Accordion End*/}

        </Accordion>
        {/*Accordion End*/}

        <div style={{ padding: '0 15px' }}>

          <div className="acc_bot_bold_t">
            <p className="pt-3" style={{ fontSize: '14px', lineHeight: '1.1' }}>{restData ? restData.NOTES[lang]?restData.NOTES[lang]:restData.NOTES.en : null}</p>
            <p className="mb-1" style={{ fontSize: '16px', fontWeight: '800' }}>{restData ? restData.TEXT_SHORT[lang]?restData.TEXT_SHORT[lang]:restData.TEXT_SHORT.en : null}</p>
            <p className="mb-1" style={{ fontSize: '16px', fontWeight: '800', lineHeight: '1.1' }}>{restData ? restData.TEXT_LONG[lang]?restData.TEXT_LONG[lang]:restData.TEXT_LONG.en : null}</p>
            {/* <p className="mb-4" style={{ fontSize: '16px', fontWeight: '800' }}>**Prodotto surgelato</p> */}
          </div>

        </div>

      </div>
      <Footer />
    </>
  )
}


export default AccordionScreen;