import { CATEGORY_BY_REST_ID } from "../constant/constants";

export default (state = '', action) =>{
    switch(action.type){
        case CATEGORY_BY_REST_ID:
            return{
                state,
                catbyid: action.payload.data,
            }
        default:
            return state
    }
}